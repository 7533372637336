import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'


const routes: Array<RouteRecordRaw> = [
  {
    path: '/home',
    name: 'home',
    component: () => import(/* webpackChunkName: "about" */ '../views/HomeView.vue')
  },
  {
    path: '/about/:id',
    name: 'about',
    component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  },
  {
    path: '/picture',
    name: 'picture',
    component: () => import(/* webpackChunkName: "about" */ '../views/PictureView.vue')
  },
  {
    path: '/detailed',
    name: 'detailed',
    component: () => import(/* webpackChunkName: "about" */ '../views/DetailedView.vue')
  },
  {
    path: '/recharge',
    name: 'recharge',
    component: () => import(/* webpackChunkName: "about" */ '../views/RechargeView.vue')
  },
  {
    path: '/department',
    name: 'department',
    component: () => import(/* webpackChunkName: "about" */ '../views/DepartmentView.vue')
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
