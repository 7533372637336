import { createStore } from 'vuex'

export default createStore({
  state: {
    token: localStorage.getItem('token') ? localStorage.getItem('token') : '',
    // uid: localStorage.getItem('uid') ? localStorage.getItem('uid') : '0',
    project: 'bnx',
    platform: 'h5',
    version:'2.0.01',
    rand: '',
    time: '',
    guid: '',
    authCode:'',
    cityCode:'',
    campusName:'',
    shou:true,
    list:{},
    listactive:"0",
    name:'',
    idnumber:'',
    department:''
  },
  getters: {
    token: state => state.token,
    // uid: state => state.uid,
    project: state => state.project,
    platform: state => state.platform,
    version: state => state.version,
    rand: state => state.rand,
    time: state => state.time,
    guid: state => state.guid,
    authCode: state => state.authCode,
    cityCode: state => state.cityCode,
    campusName: state => state.campusName,
    shou: state => state.shou,
    list: state => state.list,
    listactive: state => state.listactive,
    name: state => state.name,
    idnumber: state => state.idnumber,
    department: state => state.department,
    
  },
  mutations: {
    // SET_UID(state, value) {
    //   state.uid = value
    //   state.uid ? localStorage.setItem('uid', state.uid) : localStorage.removeItem('uid')
    // },
    SET_NAME(state, value){
      console.log(state, value);
      
      state.name = value
    },
    SET_DEPARTMENT(state, value){
      console.log(state, value);
      
      state.department = value
    },
    SET_IDNUMBER(state, value){
      console.log(state, value);
      
      state.idnumber = value
    },
    SET_TOKEN(state, value) {
      console.log(state, value);
      
      state.token = value
      state.token ? localStorage.setItem('token', state.token) : localStorage.removeItem('token')
    },
    SET_AUTOCODE(state, value){
      console.log(state, value);
      
      state.authCode = value
    },
    SET_CAMPUSNAME(state, value){
      console.log(state, value);

      state.campusName = value
    },
    SET_CITYCODE(state, value){
      console.log(state, value);

      state.cityCode = value
    },
    SET_SHOU(state, value){
      console.log(state, value);
      state.shou = value
    },
    SET_LIST(state, value){
      console.log(state, value);
      state.list = value
    },
    SET_LISTACTIVE(state, value){
      console.log(state, value);
      state.listactive = value
      state.listactive ? localStorage.setItem('listactive', state.listactive) : localStorage.removeItem('listactive')

    }
  },
  actions: {
  },
  modules: {
  }
})
