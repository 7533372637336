<template>
  
  <div>
    <keep-alive>
      <router-view/>
    </keep-alive>
    </div>
</template>
<script>
if (window.chrome && window.chrome.inspect) {
    window.chrome.inspect();
}
</script>
<style>
*{
  margin: 0;
  padding: 0;
}
body{
  /* background-color: #f3f3f3; */
}

</style>
